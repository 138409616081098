import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  cards() {
    return api.get(`/api/v1/statistic`);
  },
  chart(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/statistic/installation?${params}`);
  },
  clientCabinetChart(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/statistic/client-cabinet?${params}`);
  },
  cabinet(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/statistic/cabinet?${params}`);
  },
  pole(id) {
    return api.get(`/api/v1/statistic/pole?cabinet_id=${id}`);
  },
  statisticsBalance() {
    return api.get(`/api/v1/statistic/get-balance?take=1`);
  },
  ftthSmsBalance() {
    return api.get(`/api/v1/ftth/get-balance`);
  },
  newCustomerStatistic(data) {
    return api.get(
      `/api/v1/statistic/new-customer?start_date=${data.start_date}&end_date=${data.end_date}`
    );
  },
  cabinetGrowth(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/cabinet-daily-growth?${params}`);
  },
};

import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import dayjs from "dayjs";
const reportsApi = factory.get("reports");

const initialState = {
  data: [],
  total: 0,
  loading: false,
  dialog: {
    open: false,
    data: [],
    summary: null,
    total: 0,
    name: "",
  },
  filters: {
    target: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      user_id: null,
      city_id: null,
      is_supervisor: 1,
    },
    targetSummery: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      user_id: null,
    },
    salesCustomers: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      user_id: null,
      cabinet: null,
      city_id: null,
    },
    subscriptions: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      city_id: null,
      is_free: 0,
      created_by_ids: [],
      customers_source: null,
    },
    maintenance: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      start_time: null,
      end_time: null,
      city_id: null,
      type: null,
      customers_source: null,
    },
    newCustomersTasks: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      city_id: null,
    },
    cabinets: {
      city_id: null,
    },
    cabinetsByDate: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      city_id: null,
    },
    poles: {
      cabinet_id: null,
    },
    kpi: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      // user_id: null,
    },
    expiryCustomers: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      user_id: null,
      user_id_details: null,
    },
    noSessions: {
      city_id: null,
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      is_client: null,
    },
    clientBalances: {
      date: dayjs().format("YYYY-MM-DD"),
    },
    installation: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      customers_source: null,
      city_id: null,
      created_by_client: null,
    },
    clientUsers: {},
    allSupervisorInstallation: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
    },
    cabinetNewInstallStatistics: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
    },
    cabinetSummary: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      cabinet_id: null,
    },
    cabinetSummaryDetails: {
      cabinet_id: null,
      cabinet_name: "",
    },
  },
  payload: [],
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setReports: (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
    },
    clearReports: (state) => {
      state.data = [];
      state.total = 0;
      state.payload = [];
    },
    setFilters: (state, { payload }) => {
      state.filters[payload.type] = {
        ...state.filters[payload.type],
        ...payload.data,
      };
    },
    setDialog: (state, { payload }) => {
      state.dialog.open = !state.dialog.open;
      state.dialog.name = payload || "";
      state.dialog.data = [];
    },
    setDialogData: (state, { payload }) => {
      state.dialog.data = payload.data || [];
      state.dialog.total = payload.total || 0;
    },
    setDialogLoading: (state) => {
      state.dialog.loading = !state.dialog.loading;
    },
    setPayload: (state, { payload }) => {
      state.payload = payload;
    },
    setSummary: (state, { payload }) => {
      state.dialog.summary = payload || null;
    },
  },
});

export const {
  setLoading,
  setReports,
  clearReports,
  setDialog,
  setDialogOpen,
  setFilters,
  setDialogData,
  setDialogLoading,
  setPayload,
  setSummary,
} = reportsSlice.actions;

export default reportsSlice.reducer;

// Axios
export const fetch = (reportName) => async (dispatch, getState) => {
  try {
    dispatch(clearReports());
    dispatch(setLoading());
    const filters = getState().reports.filters;
    const response = await reportsApi[reportName](filters[reportName]);
    if (response.data.payload) {
      dispatch(setPayload(response.data.payload));
    }
    dispatch(setReports(response.data));
    dispatch(setLoading());
  } catch (error) {
    dispatch(setLoading());
    dispatch(
      showNotification({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};
export const targetSummary = () => async (dispatch, getState) => {
  try {
    dispatch(setDialogLoading());
    const filters = getState().reports.filters.targetSummery;
    const response = await reportsApi.targetSummary({
      ...filters,
      skip: 0,
      take: 200000,
    });
    dispatch(setDialogData(response.data));
    dispatch(setDialogLoading());
  } catch (error) {
    dispatch(setDialogLoading());
    dispatch(
      showNotification({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};
export const poles = () => async (dispatch, getState) => {
  try {
    dispatch(setDialogLoading());
    const { cabinet_id } = getState().reports.filters.poles;
    const response = await reportsApi.poles(cabinet_id);
    dispatch(setDialogData(response.data));
    dispatch(setDialogLoading());
  } catch (error) {
    dispatch(setDialogLoading());
    dispatch(
      showNotification({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};
export const expiryCustomersDetails = () => async (dispatch, getState) => {
  try {
    dispatch(setDialogLoading());
    const filters = getState().reports.filters.expiryCustomers;
    const response = await reportsApi.expiryCustomersDetails({
      start_date: filters.start_date,
      end_date: filters.end_date,
      user_id: filters.user_id_details,
      skip: 0,
      take: 200000,
    });
    dispatch(setDialogData(response.data));
    dispatch(setDialogLoading());
  } catch (error) {
    dispatch(setDialogLoading());
    dispatch(
      showNotification({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};
export const cabinetSummaryDetails = () => async (dispatch, getState) => {
  try {
    dispatch(setDialogLoading());
    const { start_date, end_date } = getState().reports.filters.cabinetSummary;
    const { cabinet_id, cabinet_name } =
      getState().reports.filters.cabinetSummaryDetails;

    const response = await reportsApi.cabinetSummaryDetails({
      start_date,
      end_date,
      cabinet_id,
    });

    const data = response.data.data;
    const total = response.data.total;
    const summary = response.data.payload.summary;

    const updatedData = data.map((row) => ({
      ...row,
      cabinet_name,
    }));

    dispatch(setSummary(summary));

    dispatch(
      setDialogData({
        data: updatedData,
        total,
      })
    );
    dispatch(setDialogLoading());
  } catch (error) {
    dispatch(setDialogLoading());
    dispatch(
      showNotification({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

export const Reports = {
  fetch,
  targetSummary,
  poles,
  expiryCustomersDetails,
  cabinetSummaryDetails,
};

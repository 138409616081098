import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/client/client?${p}`);
  },
  getList(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/client/client/list?${p}`);
  },
  getById(id) {
    return api.get(`/api/v1/client/client/${id}`);
  },
  changePassword(data) {
    return api.post(`/api/v1/client/change-password/${data.id}`, {
      password: data.password,
    });
  },
  create(data) {
    return api.post(`/api/v1/client/client`, data);
  },
  update(id, data) {
    return api.post(`/api/v1/client/client/${id}`, data);
  },
  deleteAttachment(id) {
    return api.delete(`/api/v1/attachment/${id}`);
  },
  changeStatus(id) {
    return api.put(`/api/v1/client/active/${id}`);
  },
  deleteClient(id) {
    return api.delete(`/api/v1/client/client/${id}`);
  },
  assignClientCabent(data) {
    return api.put(`/api/v1/client/cabinet/client${data.id}`, data);
  },
  // transactions
  getTransactions(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/client/transaction?${p}`);
  },
  addRemoveBalance(data) {
    return api.post(`/api/v1/client/transaction/popup/${data.id}`, data);
  },
  addRemoveBalanceForCollector(data) {
    return api.post(
      `/api/v1/client/transaction/topup-by-collector/${data.id}`,
      data
    );
  },
  addManualCommissions(data) {
    return api.post(`/api/v1/client/commissions/${data.id}`, data);
  },
  addManualBalance(data) {
    return api.post(`/api/v1/client/balance/${data.id}`, data);
  },
  exportToExcel(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/client/transaction?${p}`, {
      responseType: "blob",
    });
  },
  exportToExcelClients(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/client/client?${p}`, {
      responseType: "blob",
    });
  },
  changeClinetVirtual(data) {
    return api.put(`/api/v1/client/virtual/${data.id}`, data);
  },
};

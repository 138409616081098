import api from "../../api/apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export

const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  target(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/target?${params}`);
  },
  targetSummary(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/target-summary?${params}`);
  },
  salesCustomers(p) {
    return api.post(`/api/v1/report/sales-customer`, p);
  },
  subscriptions(data) {
    // const params = paramsFactory(p);
    return api.post(`/api/v1/report/subscription`, data);
  },
  cabinets(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/cabinet?${params}`);
  },
  poles(id) {
    return api.get(`/api/v1/report/pole?cabinet_id=${id}`);
  },
  maintenance(p) {
    return api.post(`/api/v1/report/maintenance`, p);
  },
  kpi(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/kpi?${params}`);
  },
  cabinetsByDate(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/cabinet-growth?${params}`);
  },
  newCustomersTasks(p) {
    return api.post(`/api/v1/report/new-customers-tasks`, p);
  },
  expiryCustomers(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/canceled-by?${params}`);
  },
  expiryCustomersDetails(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/canceled-customer?${params}`);
  },
  noSessions(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/no-session?${params}`);
  },
  clientBalances(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/client-balance?${params}`);
  },
  clientUsers() {
    return api.get(`/api/v1/report/client-customers`);
  },
  installation(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/installation?${params}`);
  },
  allSupervisorInstallation(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/all-supervisor-installation?${params}`);
  },
  cabinetNewInstallStatistics(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/cabinet-statistics?${params}`);
  },
  cabinetSummary(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/cabinet-summary?${params}`);
  },
  cabinetSummaryDetails(p) {
    const params = paramsFactory(p);
    return api.get(`/api/v1/report/clients-cabinet?${params}`);
  },
};
